import { Controller, FieldValues } from 'react-hook-form'
import { ReactElement } from 'react';
import { Dropdown } from '@/common/ui/dropdown/dropdown';
import { DropdownControlledProps } from '@/common/ui/dropdown/dropdown.types';


export function DropdownControlled<TFieldValues extends FieldValues = FieldValues>({
    control,
    ...props
}: DropdownControlledProps<TFieldValues>): ReactElement {
    return (
        <Controller
            control={ control }
            name={ props.name }
            render={ ({ field }) => (
                <Dropdown
                    { ...field }
                    { ...props }
                />
            ) }
        />
    )
}