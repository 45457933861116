import cn from 'classnames';
import { TUISize } from '../../types/common';
import styles from './loader.module.scss';

type UILoaderProps = {
    size?: TUISize
    className?: string
    inverted?: boolean
}

export function UILoader({
    className,
    size,
    inverted,
}: UILoaderProps) {
    return (
        <div className={ cn(styles.FUILoader, className, {
            [`-${ size }`]: size,
            '-inverted': inverted,
        }) }>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}