import React, { MouseEventHandler, ReactNode, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useLocation } from 'react-router';
import { UIDivider } from 'finbox-ui-kit';
import { UIIcon } from 'finbox-ui-kit/components/icon';
import { useLockScrolling } from 'finbox-ui-kit/utils/hooks/useLockScrolling';
import { MenuItemCounters } from 'finbox-ui-kit/components/top-menu/components/menu-item-counters';
import { TCounters, TFUITopMenuItem, TFUITopMenuItemSubItem, TFUITopMenuProps } from '../desktop/top-menu';
import styles from './top-menu-mobile.module.scss';

const MenuLogoStyled = styled(Link)<{ bgc: string, fc: string }>`
    background-color: ${ (props) => props.bgc };
    color: ${ (props) => props.fc } !important;
`;


function MenuDropdownItems({ name, items, secondary, onClick, desktop, counters, itemCounters }: {
    name: string;
    items: TFUITopMenuItemSubItem[];
    secondary: boolean;
    desktop: boolean;
    onClick: (e: any) => void;
    counters?: TCounters;
    itemCounters?: string[];
}) {
    const [ opened, setOpened ] = useState(false);
    const handlerClick = useCallback<MouseEventHandler<HTMLAnchorElement>>((e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpened((p) => !p);
    }, []);

    const handlerClickChildren = useCallback(() => {
        if (!desktop) {
            setOpened(false);
        }
    }, [ desktop ]);

    const countersGroup = (itemCounters && counters && itemCounters.some((c) => c in counters))
        ? (
            <MenuItemCounters
                className='ml_5 mr_5'
                itemCounters={ itemCounters }
                counters={ counters }
            />
        )
        : null;

    return (
        <div
            key={ `${ name }` }
            className={ cn(
                styles.FUITopMenuItem,
                {
                    [styles.FUITopMenuItemOpened]: opened,
                },
            ) }
        >
            <a href='#' onClick={ handlerClick }>
                <span style={ { flex: 1 } }>{ name }</span>
                { countersGroup }
                <UIIcon
                    className={ styles.FUITopMenuItemCaret }
                    name='chevron-down'
                    color='white'
                />
            </a>
            <div
                className={ cn(styles.FUITopMenuSubitems, {
                    [styles.FUITopMenuSubitemsOpened]: opened,
                    [styles.FUITopMenuSubitemsSecondary]: secondary,
                }) }
            >
                <div className={ styles.FUITopMenuSubitemsExpandable }>
                    <RenderItems
                        items={ items }
                        onClick={ (e) => {
                            onClick && onClick(e);
                            handlerClickChildren();
                        } }
                        secondary
                        desktop={ desktop }
                        counters={ counters }
                    />
                </div>
            </div>
        </div>
    );
}

function RenderItems({ items, onClick, secondary, desktop, counters }: {
    items: (TFUITopMenuItem | TFUITopMenuItemSubItem)[]
    onClick?: (e: any) => void;
    secondary?: boolean;
    desktop?: boolean;
    counters?: TCounters;
}): React.ReactElement {
    const handlerClick = useCallback((itemOnClick) => (e) => {
        if (onClick) {
            onClick(e);
        }
        if (itemOnClick) {
            itemOnClick(e);
        }
    }, [ onClick ]);

    return (
        <>
            { items.map((item, index) => {
                const countersGroup = ('counters' in item && counters && item.counters.some((c) => c in counters))
                    ? (
                        <MenuItemCounters
                            className='ml_5'
                            itemCounters={ item.counters }
                            counters={ counters }
                        />
                    )
                    : null;


                if ('render' in item) {
                    return (
                        <div key={ index } className={ cn(styles.FUITopMenuItem, styles.FUITopMenuItemFluid) }>
                            { item.render }
                        </div>
                    );
                }
                if ('divider' in item) {
                    return (
                        <div key={ index } className={ cn('mt-5 ml-5 mr-5', styles.FUITopMenuItemDivider) }>
                            <UIDivider content={ item.text } size='small'/>
                        </div>
                    );
                }
                if ('items' in item) {
                    return (
                        <MenuDropdownItems
                            key={ index }
                            name={ item.name }
                            items={ item.items }
                            secondary={ secondary }
                            desktop={ desktop }
                            onClick={ onClick }
                            itemCounters={ item.counters }
                            counters={ counters }
                        />
                    );
                }
                if ('to' in item) {
                    return (
                        <div key={ index } className={ styles.FUITopMenuItem }>
                            <Link to={ item.to } onClick={ handlerClick(item.onClick) }>
                                { item.name }
                                { countersGroup }
                            </Link>
                        </div>
                    );
                }
                if ('href' in item) {
                    return (
                        <div key={ `${ item.name }-${ item.href }` } className={ styles.FUITopMenuItem }>
                            <a href={ item.href } onClick={ handlerClick(item.onClick) }>
                                { item.name }
                                { countersGroup }
                            </a>
                        </div>
                    );
                }
                return null;
            }) }
        </>
    );
}

export function UITopMenuMobile({
    items,
    icons,
    logoBackgroundColor,
    logoTextColor,
    logoText,
    logoLink,
    counters,
    search,
    desktop = false,
}: TFUITopMenuProps & {
    desktop?: boolean
    search?: ReactNode;
}) {
    const { pathname } = useLocation();
    const { lockScrolling, unlockScrolling } = useLockScrolling();
    const [ opened, setOpened ] = useState(desktop);

    useEffect(() => {
        if (!desktop) {
            setOpened(false);
            unlockScrolling();
        }
    }, [ desktop, pathname, unlockScrolling ]);

    const handlerClickMenuButton = () => {
        if (!desktop) {
            setOpened((p) => !p);
            if (opened) {
                unlockScrolling();
            } else {
                lockScrolling();
            }
        }
    };

    const handlerClickItem = useCallback(() => {
        if (!desktop) {
            setOpened(false);
            unlockScrolling();
        }
    }, [ desktop, unlockScrolling ]);

    return (
        <div className={ styles.FUITopMenu }>
            <MenuLogoStyled
                to={ logoLink }
                className={ styles.FUITopMenuLogo }
                bgc={ logoBackgroundColor }
                fc={ logoTextColor }
                onClick={ handlerClickItem }
            >
                { logoText }
            </MenuLogoStyled>
            <div className={styles.FUITopMenuSearch}>
                { search }
            </div>
            <div className={ cn(styles.FUITopMenuItems, {
                [styles.FUITopMenuItemsOpened]: opened,
            }) }>
                { opened && (
                    <RenderItems
                        items={ items }
                        onClick={ handlerClickItem }
                        counters={ counters }
                        desktop
                    />
                ) }
            </div>

            { icons.length > 0 && (
                <div className={ styles.FUITopMenuIcons }>
                    { icons.map((icon, index) => (
                        <div key={ index } style={ { display: 'flex', alignItems: 'center' } }>{ icon.render }</div>
                    )) }
                </div>
            ) }
            { !desktop && (
                <div className={ styles.FUITopMenuMenuButton } onClick={ handlerClickMenuButton }>
                    <UIIcon name={ opened ? 'xmark' : 'bars' } color='white' size='small'/>
                </div>
            ) }
        </div>
    );
}

export default UITopMenuMobile;