import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { TOptionProps, TUIOptionsSelectHandler, TUIOptionsProps, TUIOptionsOption } from './options';
import './options.style.scss';
import { UIIcon } from '../icon';
import { UIPopup } from '../popup';
import { extractString } from '../../utils';


const scrollToSelectedItem = () => {
    document.querySelector('li.-selected').scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
    });
};

const itemKey = (item: TUIOptionsOption, index: number) => `${ index }:${ item.text }:${ item.value }`;

const UIOption: React.FC<TOptionProps> = ({
    item,
    selected,
    onClick,
    picked,
    disabled,
    color,
    items,
    openedItems,
}) => {
    const optionRef = useRef(null);
    const handlerClick = useCallback(() => {
        if (disabled) {
            return;
        }
        onClick(item);
    }, [ disabled, item, onClick ]);

    const handlerSelect: TUIOptionsSelectHandler = (item) => {
        onClick(item);
    }

    const onClickOutside = () => {
        // setOpen(false);
    }

    return (
        <li
            ref={ optionRef }
            role='option'
            className={ cn('FUI-options-item', {
                '-selected': selected,
                '-picked': picked,
                '-disabled': disabled,
                [`-${ color }`]: color,
            }) }
            onMouseDown={ handlerClick }
            title={ extractString(item.text) }
        >
            { picked && (<UIIcon type='light' name='check' color='green' className='mr-5'/>) }
            { item.text }
            { items && (
                <UIIcon
                    type='light'
                    name='chevron-right'
                    className='float-right ml-5'
                />
            ) }
            { items && openedItems && (
                <UIPopup
                    targetRef={ optionRef }
                    open={ openedItems }
                    onClickOutside={ onClickOutside }
                    position='left top'
                    minWidth={0}
                >
                    <UIOptionsList
                        options={ items }
                        onSelect={ handlerSelect }
                    />
                </UIPopup>
            ) }
        </li>
    );
};


export const UIOptionsList: React.FC<TUIOptionsProps> = React.forwardRef<HTMLUListElement, TUIOptionsProps>(function UIOptions(
    {
        active = true,
        options,
        filter,
        filtering,
        selectOnNavigation,

        onSelect,
        onEnter,

        className,
        fluid,
        selected: propsSelected,
        disabled,
    },
    ref,
) {
    const [ selected, setSelected ] = useState(-1);
    const [ filteredOptions, setFilteredOptions ] = useState<TUIOptionsOption[]>([]);

    useEffect(() => {
        if (filter && filtering) {
            setFilteredOptions(
                options.filter((i) => (i.text as string).toLowerCase().startsWith(filter.toLowerCase())),
            );
        } else {
            setFilteredOptions(options);
        }
    }, [ options, filter, filtering ]);

    const returnSelected = useCallback((index: number) => {
        const item = filteredOptions[index];
        if (item) {
            onSelect(item);
        }
    }, [ filteredOptions, onSelect ]);

    const handlerArrows = useCallback((e: KeyboardEvent) => {
        switch (e.key) {
            case 'ArrowDown': {
                e.preventDefault();
                if (!filteredOptions.length) {
                    return;
                }
                const index = (selected + 1 >= filteredOptions.length) ? 0 : selected + 1;
                setSelected(index);
                if (selectOnNavigation) {
                    returnSelected(index);
                }
                scrollToSelectedItem();
            }
                break;
            case 'ArrowUp': {
                e.preventDefault();
                if (!filteredOptions.length) {
                    return;
                }
                const index = (selected - 1 < 0) ? filteredOptions.length - 1 : selected - 1;
                setSelected(index);
                if (selectOnNavigation) {
                    returnSelected(index);
                }
                scrollToSelectedItem();
            }
                break;
            case 'Enter': {
                e.preventDefault();
                if (!filteredOptions.length || selected === -1) {
                    return;
                }
                returnSelected(selected);

                if (onEnter) {
                    onEnter();
                }
            }
                break;
            default:
                return;
        }
    }, [ filteredOptions.length, selected, selectOnNavigation, returnSelected, onEnter ]);

    useEffect(() => {
        if (active) {
            window.addEventListener('keydown', handlerArrows);
        }
        return () => {
            window.removeEventListener('keydown', handlerArrows);
        };
    }, [ active, handlerArrows ]);

    useEffect(() => {
        let existIndex = 0;
        if (open) {
            existIndex = options.findIndex((i) => i.text === filter);
        }
        setSelected(existIndex === -1 ? 0 : existIndex);
    }, [ filter, options ]);

    const [ openedSubItems, setOpenedSubItems ] = useState('');

    const handlerClick = useCallback<TUIOptionsSelectHandler>((item) => {
        if (item.items) {
            setOpenedSubItems(String(item.value));
            return;
        }

        const index = filteredOptions.findIndex((i) => i.value === item.value);
        setSelected(index);
        onSelect(item);
    }, [ filteredOptions, onSelect ]);


    return (
        <ul
            role='listbox'
            ref={ ref }
            className={ cn('FUI-options-list', className, {
                '-fluid': fluid,
            }) }
        >
            { filteredOptions.length === 0 && (
                <div className='FUI-options-empty'>Нет подходящих вариантов</div>
            ) }
            { filteredOptions.map((item, idx) => (
                <UIOption
                    key={ itemKey(item, idx) }
                    item={ item }
                    onClick={ handlerClick }
                    selected={ idx === selected || item.value === propsSelected }
                    picked={ item.picked }
                    disabled={ disabled || item.disabled }
                    color={ item.color }
                    items={ item.items }
                    openedItems={openedSubItems === String(item.value)}
                />
            )) }
        </ul>
    );
});
