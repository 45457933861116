import cn from 'classnames';
import { TGridColumn } from './types';
import styles from './grid.module.scss';


export const GridColumn: TGridColumn = ({
    children,
    className,
    style = {},
    fluid,
    colspan,
    rowspan,
    onClick,
    verticalAlign,
}) => (
    <div
        className={ cn(styles.gridColumn, className, {
            [styles.gridColumnFluid]: fluid,
            [styles.gridColumnVerticalAlignMiddle]: verticalAlign === 'middle',
        }) }
        style={ {
            // @ts-ignore
            '--grid-colspan': colspan || 1,
            '--grid-column-rowspan': rowspan || 1,
            ...style,
        } }
        onClick={ onClick }
    >
        { children }
    </div>
);