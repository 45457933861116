import { UILoader } from '../loader';
import styles from './loading-overlay.module.scss';

type UILoadingOverlayProps = {
    active: boolean
    inverted?: boolean
}
export function UILoadingOverlay({ active, inverted = true }: UILoadingOverlayProps) {
    if (!active) {
        return null;
    }
    return (
        <div className={ styles.FUILoadingOverlay }>
            <UILoader size='small' inverted={inverted}/>
        </div>
    );
}