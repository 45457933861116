import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { formatInitials } from 'finbox-ui-kit/utils';
import { formatPhoneNumber, normalizePhone, prettifyPhone } from '@/utils';
import { CHANNEL_ICON } from '@/consts';
import { useDictionary } from '@/utils/hooks/use-dictionary';
import { Dropdown } from '@/common/ui/dropdown';
import { useAuthContext } from '@/context/auth.context';
import { Button, Icon, Message, Modal, ModalProps, Textarea } from '@/common/ui';
import { joiSchema } from '@/libs/joi';
import { TIconBrandName, TIconName } from '@/common/ui/icon/types';
import { TColor } from '@/types/common';
import { TUser } from '@/types/manager';
import { TClient } from '@/types/lead';
import { TProduct } from '@/types/product';


type TMessageDialogProps = ModalProps & {
    number: string | number;
    icon?: TIconName | TIconBrandName;
    iconColor?: TColor;
    onSubmit: (text) => Promise<boolean>;
    text?: string;
    loading?: boolean;
    isWhatsApp?: boolean;
    isSent?: boolean;
    extendsStringPatterns?: string[];
    client?: TClient;
}

type TForm = {
    text: string;
}

const scheme = joiSchema((joi) => joi.object({
    text: joi.string().required(),
}));

export const replacePatterns = (text: string, user: TUser, client?: TClient, products?: TProduct[]) => {
    const patterns = {
        lead_manager: formatInitials(user, true),
        manager_name: formatInitials(user, true),
        manager_phone: prettifyPhone(user.phone),
        manager_mail: user.mail,
        ...client && {
            client_name: client.client.initials.name,
            client_surname: client.client.initials.surname,
            client_patronymic: client.client.initials.patronymic,
            client_product: products.find((p) => p.id === (client.client.products[0]?.pid || client.client.products[0]))?.name,
        },
    };
    return Object.keys(patterns).reduce((res, pattern) => {
        const re = new RegExp(`#${pattern}#`, 'gi');
        return res.replace(re, patterns[pattern]);
    }, text);
};

const MessageDialog = ({
    number,
    icon = 'envelope',
    iconColor,
    onSubmit,
    text: initText,
    loading,
    isWhatsApp,
    isSent,
    extendsStringPatterns,
    client,
    ...modalProps
}: TMessageDialogProps) => {
    const { user } = useAuthContext();
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [ template, setTemplate ] = useState(null);
    const [ templates ] = useDictionary('smsTemplates');
    const [ products ] = useDictionary('products');

    const form = useForm<TForm>({
        resolver: scheme,
        values: {
            text: initText,
        },
    });

    useEffect(() => {
        if (modalProps.open) {
            form.reset();
            setTemplate(null);
        }
    }, [ modalProps.open, form ]);

    const handlerSubmit = async (fields: TForm) => {
        await onSubmit(fields.text);
        if (!isSent && !isWhatsApp) {
            modalProps.onClose();
        }
    };

    const templateChangeHandler = (value: number) => {
        const tpl = templates.find((i) => i.id === value);
        setTemplate(value);
        form.setValue('text', replacePatterns(tpl.text, user, client, products));
        form.clearErrors('text');
    };

    const stringTemplates: string[] = [
        ...(extendsStringPatterns || []),
        'Здравствуйте. ',
        formatInitials(user, true),
        formatPhoneNumber(user.phone),
        user.mail,
    ];

    function handlerClickStringPattern(text: string) {
        return () => {
            const textarea = textareaRef.current;
            const startPos = textarea.selectionStart;
            const endPos = textarea.selectionEnd;
            const beforeText = textarea.value.substring(0, startPos);
            const afterText = textarea.value.substring(endPos, textarea.value.length);

            form.setValue('text', beforeText + text + afterText);
            form.clearErrors('text');

            textarea.focus();

            setTimeout(() => {
                textarea.selectionStart = textarea.selectionEnd = startPos + text.length;
            }, 10);
        };
    }

    return (
        <Modal { ...modalProps } size='small' style={ { zIndex: 9999 } }>
            <Modal.Header>
                <div>
                    <Icon
                        name={ icon as TIconName }
                        color={ iconColor }
                        type={ icon !== CHANNEL_ICON.SMS ? 'brands' : 'regular' as any }
                        size='big'
                    />&nbsp;
                    Сообщение абоненту <span className='nowrap'>{ formatPhoneNumber(number) }</span>
                </div>
            </Modal.Header>
            <Modal.Content>

                { isSent && (
                    <Message icon='circle-check' color='green'>
                        Сообщение отправлено
                    </Message>
                ) }

                { !isSent && (
                    <form id='message-dialog-form' onSubmit={ form.handleSubmit(handlerSubmit) } noValidate>
                        <Dropdown
                            className='mb1'
                            label='Шаблон'
                            name='template'
                            onChange={ templateChangeHandler }
                            value={ template }
                            options={ (templates || []).map((i, key) => ({
                                key,
                                value: i.id,
                                text: i.name,
                            })) }
                            clearable
                        />
                        <Textarea.Controlled
                            ref={ textareaRef }
                            control={ form.control }
                            label='Текст'
                            name='text'
                            error={ form.formState.errors?.text }
                            rows={ 10 }
                            required
                            clearable
                        />
                        <div className='mt-5' style={ {
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 7,
                        } }>
                            { stringTemplates.map((text, idx) => (
                                <Button
                                    type='button'
                                    key={ idx }
                                    content={ text }
                                    color='white'
                                    onClick={ handlerClickStringPattern(text) }
                                    compact
                                />
                            )) }
                        </div>
                    </form>
                ) }
            </Modal.Content>
            <Modal.Actions>
                { isWhatsApp && (
                    <Button
                        type='button'
                        content='Открыть чат'
                        as='a'
                        href={ `whatsapp://send?phone=${ normalizePhone(number) }` }
                        target='_blank'
                        rel='noopener noreferrer'
                        icon='whatsapp'
                        iconType='brands'
                        iconColor='green'
                        color='white'
                    />
                ) }
                <div style={ { flex: 1 } }></div>
                { !isSent && (
                    <Button
                        form='message-dialog-form'
                        content='Отправить'
                        icon='paper-plane'
                        loading={ loading }
                        primary
                    />
                ) }
                <Button
                    content={ !isSent ? 'Отмена' : 'Закрыть' }
                    disabled={ loading }
                    onClick={ modalProps.onClose }
                />
            </Modal.Actions>
        </Modal>
    );
};

export {
    MessageDialog,
};
