import cn from 'classnames';
import { TGridComponent } from './types';
import { GridRow } from './grid-row';
import { GridColumn } from './grid-column';
import styles from './grid.module.scss';


export const Grid: TGridComponent = ({
    children,
    className,
    style,
    stackable,
    noRowGap,
    bordered,
}) => (
    <div
        className={ cn(styles.grid, className, {
            [styles.gridNoRowGap]: noRowGap,
            [styles.gridBordered]: bordered,
            [styles.gridStackable]: stackable,
        }) }
        style={ style }
    >
        { children }
    </div>
);

Grid.Row = GridRow;
Grid.Col = GridColumn;