import { useCallback, useState } from 'react';
import { Controller, useFormContext, FieldError } from 'react-hook-form';
import get from 'lodash/get';
import { useDebounce } from 'finbox-ui-kit/utils/hooks';
import { API_URLS } from '@/consts';
import { TFamilyForm } from '@/components/leads/components/client-edit-forms/types';
import { useApiClient } from '@/libs/api-client/use-api-client';
import { InputSuggestions, InputSuggestionsOptions } from '@/common/ui/input-suggestions';
import { Nullable } from '@/types/_helpers';
import { Grid } from '@/common/ui/grid';
import { InputAddress } from '@/common/ui/input-address';
import { Input } from '@/common/ui/input';

type PassportFormType = any & {
    [x: string]: TFamilyForm
}

type FinancesFormProps = {
    path: string
}

export function PrimeTimeJobForm({ path }: FinancesFormProps) {
    const { debounce } = useDebounce({ timeout: 300 });
    const {
        control,
        formState: { errors },
        setValue,
        clearErrors,
    } = useFormContext<PassportFormType>();

    const [ companySuggestions, setCompanySuggestions ] = useState([]);

    const { loading: isLoadingOrganization, fetch: fetchOrganization } = useApiClient({
        url: API_URLS.DICTIONARIES.DADATA.ORGANIZATION,
    });

    const handlerChangeCompany = useCallback((formChangeHandler) => (value: Nullable<any>, { option }: InputSuggestionsOptions) => {
        formChangeHandler(value);

        if (option && option.data && option.data.data) {
            setValue(`${ path }.inn`, option.data.data.inn);
            setValue(`${ path }.ogrn`, option.data.data.ogrn);
            setValue(`${ path }.address`, option.data.data.address.unrestricted_value);
            clearErrors([
                `${ path }.inn`,
                `${ path }.ogrn`,
                `${ path }.address`,
            ]);
        }

        if (value) {
            debounce(async () => {
                const { suggestions } = await fetchOrganization({
                    query: value,
                });
                setCompanySuggestions(suggestions.map((i) => ({
                    text: `${ i.value } (${ i.data.address.value })`,
                    value: i.value,
                    data: i,
                })));
            });
        }
    }, [ clearErrors, debounce, fetchOrganization, path, setValue ]);

    return (
        <Grid stackable>
            <Grid.Row columns={ 4 }>
                <Grid.Col colspan={ 2 }>
                    <Controller
                        control={ control }
                        name={ `${ path }.name` }
                        render={ ({ field }) => (
                            <InputSuggestions
                                { ...field }
                                type='text'
                                label='Название организации'
                                name={ `${ path }.name` }
                                suggestions={ companySuggestions }
                                loading={ isLoadingOrganization }
                                placeholder='Начните вводить название или ИНН'
                                error={ (get(errors, `${ path }.name`) as FieldError)?.message }
                                onChange={ handlerChangeCompany(field.onChange) }
                                autoComplete='off'
                                filtering={ false }
                                clearable
                                required
                            />
                        ) }
                    />
                </Grid.Col>
                <Grid.Col>
                    <Controller
                        control={ control }
                        name={ `${ path }.inn` }
                        render={ ({ field }) => (
                            <Input
                                { ...field }
                                type='number'
                                label='ИНН'
                                name='inn'
                                error={ (get(errors, `${ path }.inn`) as FieldError)?.message }
                                required
                            />
                        ) }
                    />
                </Grid.Col>
                <Grid.Col>
                    <Controller
                        control={ control }
                        name={ `${ path }.ogrn` }
                        render={ ({ field }) => (
                            <Input
                                { ...field }
                                type='number'
                                label='ОГРН'
                                error={ (get(errors, `${ path }.ogrn`) as FieldError)?.message }
                                required
                            />
                        ) }
                    />
                </Grid.Col>
            </Grid.Row>
            <Grid.Row columns={ 3 }>
                <Grid.Col colspan={ 2 }>
                    <Controller
                        control={ control }
                        name={ `${ path }.address` }
                        render={ ({ field }) => (
                            <InputAddress
                                { ...field }
                                label='Юридический адрес'
                                name='address'
                                autoComplete='off'
                                error={ (get(errors, `${ path }.address`) as FieldError)?.message }
                                required
                            />
                        ) }
                    />
                </Grid.Col>
                <Grid.Col>
                    <Controller
                        control={ control }
                        name={ `${ path }.phone` }
                        render={ ({ field }) => (
                            <Input
                                { ...field }
                                type='tel'
                                label='Рабочий телефон'
                                name='phone'
                                mask='+7 (999) 999-99-99'
                                placeholder='+7 (000) 000-00-00'
                                autoComplete='off'
                                error={ (get(errors, `${ path }.phone`) as FieldError)?.message }
                                required
                            />
                        ) }
                    />
                </Grid.Col>
            </Grid.Row>
            <Grid.Row columns={ 3 }>
                <Grid.Col>
                    <Controller
                        control={ control }
                        name={ `${ path }.jobType` }
                        render={ ({ field }) => (
                            <Input
                                { ...field }
                                type='text'
                                label='Вид работы по совместительству'
                                error={ (get(errors, `${ path }.jobType`) as FieldError)?.message }
                                required
                            />
                        ) }
                    />
                </Grid.Col>
                <Grid.Col>
                    <Controller
                        control={ control }
                        name={ `${ path }.startDate` }
                        render={ ({ field }) => (
                            <Input
                                { ...field }
                                type='text'
                                label='Трудовой стаж с'
                                mask='99.9999'
                                placeholder='мм.гггг'
                                autoComplete='off'
                                error={ (get(errors, `${ path }.startDate`) as FieldError)?.message }
                                required
                            />
                        ) }
                    />
                </Grid.Col>
            </Grid.Row>
        </Grid>
    );
}
