import { Outlet } from 'react-router';
import { useAtom, useAtomValue } from 'jotai';
import { Footer } from '@/common/footer';
import TopMenu from '@/components/top-menu';
import { AuthUser, LeftMenuState } from '@/state/auth.state';
import { LeftMenu } from '@/common/left-menu';
import { useMediaQuery } from '@/utils/hooks';


const AppLayout = () => {
    const user = useAtomValue(AuthUser);
    const isLgDevice = useMediaQuery('lg');
    const [ leftMenu ] = useAtom(LeftMenuState);
    if (!user) return null;
    const isLeftMenu = !isLgDevice && leftMenu;

    return (
        <main style={ {
            ...isLeftMenu && {
                padding: 0,
            },
        } } className={ isLeftMenu ? 'app-left-menu' : '' }>
            { !isLeftMenu && (
                <TopMenu isAdmin={ user.admin } user={ user }/>
            ) }
            { isLeftMenu && (
                <LeftMenu isAdmin={ user.admin } user={ user }/>
            ) }
            <div className='main-block' style={ {
                ...isLeftMenu && {
                    paddingLeft: 230,
                },
            } }>
                <Outlet/>
            </div>
            <Footer/>
        </main>
    );
};

export default AppLayout;
