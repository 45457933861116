import React, { useImperativeHandle, useRef } from 'react';
import { OptionsList, TOption, TSelectHandler } from '@/common/options-list';
import { UIButton, UIPopup } from 'finbox-ui-kit';
import { useToggle } from 'finbox-ui-kit/utils/hooks';
import { UIButtonProps } from 'finbox-ui-kit/components/button';
import { TUIPopupPosition } from 'finbox-ui-kit/components/popup/popup';

type UIButtonWithDropdownProps = UIButtonProps<'button'> & {
    options: TOption[]
    onSelectOption: TSelectHandler
    position?: TUIPopupPosition
}

export const UIButtonWithDropdown = React.forwardRef<HTMLButtonElement, UIButtonWithDropdownProps>(function UIButtonWithDropdown({
    options,
    onSelectOption,
    className,
    position,
    ...buttonProps
}, forwardRef) {
    const buttonRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle(forwardRef, () => buttonRef.current, []);

    const { on: showedList, toggle: toggleList } = useToggle();
    const handlerSelect = (option: TOption) => {
        toggleList(false);
        onSelectOption(option);
    };

    return (
        <UIPopup.Wrapper className={className}>
            <UIButton
                ref={ buttonRef }
                { ...buttonProps }
                onClick={ () => toggleList(true) }
            />
            <UIPopup
                targetRef={ buttonRef }
                open={ showedList }
                onClickOutside={ () => toggleList(false) }
                position={position}
            >
                <OptionsList
                    options={ options }
                    onSelect={ handlerSelect }
                />
            </UIPopup>
        </UIPopup.Wrapper>
    );
})