import cn from 'classnames';
import { TSize } from '@/types/common';
import styles from './loader.module.scss';

type LoaderProps = {
    size?: TSize
    className?: string
    inverted?: boolean
}

export function Loader({
    className,
    size,
    inverted,
}: LoaderProps) {
    return (
        <div className={ cn(styles.loader, className, {
            [`-${ size }`]: size,
            '-inverted': inverted,
        }) }>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}