import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useToggle } from 'finbox-ui-kit/utils/hooks';
import { Popup } from '@/common/ui';
import { OptionsList, TOption, TSelectHandler } from '@/common/options-list';
import { TUser } from '@/types/manager';
import { UserAvatar } from '@/common/user-avatar';
import { API_BASE_URL_V2 } from '@/consts';
import { axiosPrivate } from '@/libs/api-client/axiosPrivate';
import { useAuthContext } from '@/context/auth.context';


interface IMenuProps {
    user: TUser;
}


export function LeftMenuUser({ user }: IMenuProps) {
    const { on, toggle } = useToggle();
    const navigate = useNavigate();
    const ref = useRef(null);
    const { doLogoutUser } = useAuthContext();

    const options: TOption[] = [
        {
            text: 'Профиль',
            value: '/profile/',
        },
        {
            text: 'График работы',
            value: '/profile/schedule/',
        },
        {
            text: 'Выход',
            value: '/user/logout/',
        },
    ];

    const { mutateAsync } = useMutation({
        mutationFn: () => axiosPrivate(`${ API_BASE_URL_V2 }/auth/logout`, {
            method: 'POST',
        }),
    });


    const handlerSelectChannel: TSelectHandler<unknown, string> = async ({ value }) => {
        toggle(false);
        if (value === '/user/logout/') {
            try {
                await mutateAsync();
            } catch (err) {
                toast.error('Ошибка выхода');
                throw err;
            }
            toast.success('До скорых встреч! ❤');
            await doLogoutUser();
            navigate('/');
            return;
        }
        navigate(value);
    };

    return (
        <Popup.Wrapper>
            <div
                ref={ ref }
                style={ { display: 'flex', alignItems: 'center', paddingLeft: 14, paddingRight: 14, color: 'black', cursor: 'pointer' } }
                onClick={ () => toggle(true) }
            >
                <div style={ { width: 28, verticalAlign: 'middle' } }>
                    <UserAvatar user={ user } small/>
                </div>
            </div>
            <Popup
                targetRef={ ref }
                open={ on }
                onClickOutside={ () => toggle(false) }
                minWidth={ 160 }
                position='right top'
            >
                <div style={ { color: '#373a3c' } }>
                    <OptionsList
                        options={ options }
                        onSelect={ handlerSelectChannel }
                    />
                </div>
            </Popup>
        </Popup.Wrapper>
    );
}