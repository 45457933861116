import { useCallback, useRef } from 'react';
type TUseDebounceProps = {
    timeout: number;
}
export const useDebounce = ({ timeout }: TUseDebounceProps) => {
    const ref = useRef<ReturnType<typeof setTimeout>>(null);

    const debounce = useCallback((cb: () => void | Promise<void>) => {
        clearTimeout(ref.current);
        ref.current = setTimeout(cb, timeout);
    }, [ timeout ]);

    const debounceClear = useCallback(() => {
        clearTimeout(ref.current);
    }, []);

    return { debounce, debounceClear };
};
