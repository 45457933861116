import { useEffect, useState } from 'react';

export const useQueryString = () => {
    const [ params, setParams ] = useState<Record<string, any>>({});

    useEffect(() => {

        const params = new URLSearchParams(window.location.search);
        // @ts-ignore
        setParams(Object.fromEntries(params.entries()))
    }, []);

    return params;
}