import React, { useEffect } from 'react';
import cn from 'classnames';


import { Button, Message } from '@/common/ui';
import { PageButtons } from '@/common/ui/page/page-buttons';
import { PageMenu } from '@/common/ui/page/page-menu';
import { Breadcrumbs } from '@/common/ui/breadcrumbs';
import { IPage, TPageContentProps, TPageErrorProps, TPageProps, TRightSideBarWrapperProps } from './page';
import styles from './page.module.scss';


const Page: React.FC<TPageProps> & IPage = (
    {
        title,
        children,
        buttons,
        breadCrumbs,
        noContentPadding,
        rightSideBar,
        beforeContent,
        documentTitle,
        flexContent,
        className,
        onBackButtonClick,
        indexLink,
        menu,
    },
) => {
    useEffect(() => {
        if (documentTitle) {
            document.title = documentTitle;
        } else if (typeof title === 'string') {
            document.title = title;
        }
    }, [ title, documentTitle ]);

    return (
        <section
            className={ cn(styles.page, {
                '-with-right-side-bar': rightSideBar,
                [styles.pageFlexContent]: flexContent,
                '-with-menu': menu,
            }, className) }
        >
            <header className={ styles.pageHeader }>
                { onBackButtonClick && (
                    <Button
                        className={ styles.pageBackButton }
                        onClick={ onBackButtonClick }
                        icon='chevron-left'
                        color='white'
                    />
                ) }
                <div className={ styles.pageHeaderContent }>
                    <h1>{ title }</h1>
                    { breadCrumbs && (
                        <Breadcrumbs items={ breadCrumbs } indexLink={ indexLink }/>
                    ) }
                </div>
                { buttons && <PageButtons buttons={ buttons }/> }
                { menu && <PageMenu menu={ menu }/> }
            </header>
            <div className={ cn(styles.pageContentWrapper, {
                [styles.pageContentWrapperNoPadding]: noContentPadding,
            }) }>
                { beforeContent }
                { children }
            </div>
        </section>
    );
};

const RightSideBarWrapper: React.FC<TRightSideBarWrapperProps> = ({ sticky, children }) => (
    <div className={ styles.pageRightSideBarWrapper }>
        <div className={ cn(styles.pageRightSideBar, { [styles.pageRightSideBarSticky]: sticky }) }>
            { children }
        </div>
    </div>
);

const LeftSideBarWrapper: React.FC<TRightSideBarWrapperProps> = ({ sticky, children }) => (
    <div className={ styles.pageLeftSideBarWrapper }>
        <div className={ cn(styles.pageLeftSideBar, { [styles.pageLeftSideBarSticky]: sticky }) }>
            { children }
        </div>
    </div>
);

const PageContent: React.FC<TPageContentProps> = ({ children, attached, className }) => (
    <div className={ cn(styles.pageContent, {
        [styles.pageContentAttached]: attached,
    }, className) }>
        { children }
    </div>
);

const PageError: React.FC<TPageErrorProps> = ({ children, className }) => (
    <div className={ cn(styles.pageError, className) }>
        <Message color='red' icon='triangle-exclamation'>{ children }</Message>
    </div>
);

Page.RightSideBarWrapper = RightSideBarWrapper;
Page.LeftSideBarWrapper = LeftSideBarWrapper;
Page.Content = PageContent;
Page.Error = PageError;


export {
    Page,
    Page as default,
};
