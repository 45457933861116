import './error-boundary.scss';
import { useRouteError } from 'react-router';
import { Button } from '@/common/ui';

export default function ErrorBoundaryPage() {
    const error: any = useRouteError();
    // eslint-disable-next-line no-console
    console.error(error);

    if (error.message?.includes('Loading chunk')) {
        window.location.reload();
    }

    return (
        <div className='error-boundary'>
            { error?.status === 404 && (
                <div className='text-center mb1 pl3 pr3'>
                    <div className='color-red' style={ { fontSize: 80, lineHeight: '1' } }>404</div>
                    страница не найдена
                </div>
            ) }
            { error?.status !== 404 && (
                <>
                    <div className='mb2'>
                        <header>
                            При загрузке компонента произошла ошибка
                        </header>
                        <small>Попробуйте перезагрузить страницу или перейдите на <a href='/'>главную страницу</a></small>
                    </div>
                    <details>
                        <summary>Подробная информация</summary>
                        <code>
                            <div className='error-boundary-error-text'>
                                { error.message }
                            </div>
                            <div className='error-boundary-error-stack'>
                                { error.stack }
                            </div>
                        </code>
                    </details>
                    <Button
                        className='mt1'
                        content='Перезагрузить страницу'
                        onClick={ () => window.location.reload() }
                        primary
                    />
                </>
            ) }
        </div>
    );
}