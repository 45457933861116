import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import { Modal, UILoadingOverlay } from 'finbox-ui-kit';
import { useDebounce } from 'finbox-ui-kit/utils/hooks';
import { formatAddress, formatManagerInitials } from '@/utils';
import { API_URLS, MCreditorOrganizationTypeShortcut } from '@/consts';
import { List } from '@/common/list';

import { useApiClient } from '@/libs/api-client/use-api-client';
import { useAuthContext } from '@/context/auth.context';
import { Input, Label, Popup } from '@/common/ui';
import styles from './search.module.scss';


const CATEGORIES = {
    leads: 'Клиенты',
    leadsByAddress: 'Клиенты по адресу',
    leadsByCadastralNumber: 'Кадастровый номер',
    contracts: 'Сделки',
    garbage: 'Корзина',
    potentialCreditors: 'Потенциальные кредиторы',
    banks: 'Кредиторы',
    calls: 'Звонки',
};
const CATEGORIES_MAP = [
    'leads',
    'leadsByAddress',
    'calls',
    'contracts',
    'garbage',
    'banks',
    'potentialCreditors',
    'leadsByCadastralNumber',
];

const mapResults = (results) => {
    const mapItem = (category, item, isPartner = false) => ({
        itemId: item.id,
        name: [ item.surname, item.name, item.patronymic ].join(' ') || 'Нет имени',
        phone: item.phone,
        categoryName: CATEGORIES[category],
        categoryType: category,
        manager: formatManagerInitials(item.manager),
        isOwner: item.isOwner,
        address: item?.address,
        organizationType: item.organizationType,
        active: item.active,
        deletedAt: item.deletedAt,
        isPartner,
    });

    let res = CATEGORIES_MAP.reduce((res, cat) => [
        ...res,
        ...(results[cat] || []).map((i) => mapItem(cat, i)),
    ], []);

    if (results?.partners?.length) {
        res = [
            ...res,
            ...results.partners.map((i) => mapItem('banks', i, true)),
        ];
    }

    return res;
};

const SearchGlobal = React.memo(function SearchGlobal({ modal }: { modal?: boolean }) {
    const inputRef = useRef(null);
    const { debounce, debounceClear } = useDebounce({ timeout: 500 });
    const { user } = useAuthContext();

    const [ results, setResults ] = useState([]);
    const [ value, setValue ] = useState('');
    const [ isActive, setIsActive ] = useState(false);
    const [ selectedCategory, setSelectedCategory ] = useState(null);
    const { fetch, loading: isLoading } = useApiClient({
        url: API_URLS.SEARCH,
    });

    const getResults = useCallback(async (query) => {
        const response = await fetch({
            s: query,
        });

        return mapResults(response.result);
    }, [ fetch ]);

    const getLinkProps = ({ name, phone, categoryType, itemId, isOwner, isPartner }) => {
        switch (categoryType) {
            case 'leads':
            case 'leadsByAddress':
            case 'leadsByCadastralNumber':
            case 'garbage':
            case 'contracts':
                if (!isOwner && !user.admin) {
                    return { link: '#', target: '_self' };
                }
                return { link: `/leads/${ itemId }/`, target: '_self' };
            case 'calls':
                return { link: `/clients/calls/?f_phone=${ phone }`, target: '_self' };
            case 'banksOrders':
                return { link: `/creditors_orders/?filters={name: "${ name }"}`, target: '_self' };
            case 'banks':
                if (isPartner) {
                    return { link: `/creditors/${ itemId }/partners/`, target: '_blank' };
                } else {
                    return { link: `/creditors/${ itemId }/`, target: '_blank' };
                }
            case 'potentialCreditors':
                return { link: `/potential-creditors/view/${ itemId }/`, target: '_blank' };
            default:
                return { link: `/leads/${ itemId }/`, target: '_self' };
        }
    };

    const handleResultSelect = useCallback(() => {
        setIsActive(false);
    }, []);

    const handleSearchChange = useCallback((e, { value }) => {
        setValue(value);
        setSelectedCategory(null);
        if (!value) {
            debounceClear();
            setResults([]);
            if (!modal) {
                setIsActive(false);
            }
            return;
        }
        if (value && value.length > 1) {
            debounce(async () => {
                setIsActive(true);
                const results = await getResults(value);
                setResults(results);
            });
        }
    }, [ debounce, debounceClear, getResults, modal ]);

    const byCats: { [x: string]: any } = useMemo(() => results.reduce((res, item) => ({
        ...res,
        [item.categoryType]: {
            name: item.categoryName,
            items: [
                ...get(res, `${ item.categoryType }.items`, []),
                item,
            ],
        },
    }), {}), [ results ]);

    const handlerCategoryClick = useCallback((code) => () => {
        setSelectedCategory(code);
    }, []);

    const handlerOutsideClick = useCallback(() => {
        setIsActive(false);
    }, []);

    const handlerInputFocus = useCallback(() => {
        if (!isEmpty(byCats) || modal) {
            setIsActive(true);
        }
    }, [ byCats, modal ]);


    const items = Object.entries(byCats)
        .filter(([ code ]) => selectedCategory === null || code === selectedCategory)
        .map((i) => i[1]);

    const totalFound = Object.values(byCats).reduce((acc, i) => acc + i.items.length, 0);

    const searchResults = (
        <div className={ styles.searchBlockResult } style={{
            width: modal ? '100%' : '650px',
            height: modal ? '400px' : 'auto',
        }}>
            <UILoadingOverlay active={ isLoading }/>
            { Object.keys(byCats).length === 0 && (
                <div className={ styles.searchBlockResultEmpty }>
                    { !isLoading ? 'Ничего не найдено' : 'Идет поиск. Пожалуйста подождите...' }
                </div>
            ) }
            { Object.keys(byCats).length > 0 && (
                <>
                    <div className={ styles.searchBlockResultCetegories }>
                        <List>
                            <List.Item
                                active={ selectedCategory === null }
                                onClick={ handlerCategoryClick(null) }
                                className={ styles.searchBlockResultCetegoriesItem }
                            >
                                Все
                                <Label className='ml-5'>
                                    { totalFound < 100 ? totalFound : '99+' }
                                </Label>
                            </List.Item>
                            { Object.entries(byCats).map(([ code, category ]) => (
                                <List.Item
                                    key={ code }
                                    active={ code === selectedCategory }
                                    onClick={ handlerCategoryClick(code) }
                                    className={ styles.searchBlockResultCetegoriesItem }
                                >
                                    { category.name }
                                    <Label className='ml-5'>
                                        { category.items.length < 100 ? category.items.length : '99+' }
                                    </Label>
                                </List.Item>
                            )) }
                        </List>
                    </div>
                    <div className={ styles.searchBlockResultList }>
                        { items.reduce((res, category, index) => [
                            ...res,
                            ...category.items.map((item, itemIndex) => {
                                const { link, target } = getLinkProps(item);
                                return (
                                    <Link
                                        key={ `${ index }-${ itemIndex }` }
                                        to={ link }
                                        target={ target }
                                        className={ styles.searchBlockResultItem }
                                        onClick={ handleResultSelect }
                                    >
                                        <div className={ styles.searchBlockResultItemMeta }>
                                            { category.name }<br/>
                                            <span className='color-grayDark'>{ item.manager }</span>
                                        </div>
                                        <b>
                                            { item.name }&nbsp;
                                            { item.categoryType === 'banks'
                                                ? `${ MCreditorOrganizationTypeShortcut.get(item.organizationType) } ` : '' }
                                            { (item.categoryType === 'banks' && !item.active) && (
                                                <span className='nowrap'>[не активен]</span>
                                            ) }
                                        </b>
                                        <div className={ `${ styles.searchBlockResultItemAddress } fz12` }>
                                            { formatAddress(item.address) }
                                        </div>
                                        { item.phone && (
                                            <span className={ `${ styles.searchBlockResultItemPhone } mr-5` }>{ item.phone }</span>
                                        ) }
                                        <span className='color-grayDarken'>№{ item.itemId }</span> &nbsp;
                                        { item.deletedAt && (
                                            <Label color='red'>Удалён</Label>
                                        ) }
                                    </Link>
                                );
                            }),
                        ], []) }
                    </div>
                </>
            ) }
        </div>
    );

    return (
        <div className={ styles.searchBlock } ref={ inputRef }>
            <Input
                className={ styles.searchBlockInput }
                name='global-search'
                value={ value }
                placeholder='Глобальный поиск'
                icon='magnifying-glass'
                onChange={ handleSearchChange }
                onFocus={ handlerInputFocus }
                autoComplete='off'
                size='small'
                clearable
            />
            { !modal && (
                <Popup
                    targetRef={ inputRef }
                    open={ isActive }
                    onClickOutside={ handlerOutsideClick }
                    position='bottom left'
                    minWidth={ 0 }
                >
                    { searchResults }
                </Popup>
            ) }
            { modal && (
                <Modal
                    open={ isActive }
                    onClose={ () => setIsActive(false) }
                >
                    <Input
                        className='mb1'
                        name='global-search'
                        value={ value }
                        placeholder='Глобальный поиск'
                        icon='magnifying-glass'
                        onChange={ handleSearchChange }
                        onFocus={ handlerInputFocus }
                        autoComplete='off'
                        clearable
                        autoFocus
                    />
                    { searchResults }
                </Modal>
            ) }

        </div>
    );
});

export {
    SearchGlobal as default,
    SearchGlobal,
};
