import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { TOptionProps, TUIOptionsSelectHandler, TUIOptionsProps, TUIOptionsOption } from './options';
import { OutsideClickHandler } from '../../utils/hooks';

import './options.style.scss';
import { extractString, refGetter, getRefTarget } from '../../utils';
import { UIIcon } from '../icon';
import { useEventListener } from '../../utils/hooks/use-event-listener';


const scrollToSelectedItem = (target: HTMLUListElement) => {
    target.querySelector('li.-selected').scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
    });
};

const itemKey = (item: TUIOptionsOption, index: number) => `${ index }:${ item.text }:${ item.value }`;

const UIOption: React.FC<TOptionProps> = ({
    item,
    selected,
    onClick,
    picked,
    disabled,
}) => {
    const optionRef = useRef(null);

    const handlerClick = useCallback(() => {
        if (disabled) {
            return;
        }
        onClick(item);
    }, [ disabled, item, onClick ]);

    return (
        <li
            ref={optionRef}
            role='option'
            className={ cn('FUI-options-item', {
                '-selected': selected,
                '-picked': picked,
            }) }
            onMouseDown={ handlerClick }
            title={ extractString(item.text) }
        >
            { picked && (<UIIcon name='check' color='green' className='mr-5'/>) }
            { item.text }
        </li>
    );
};


export const UIOptions: React.FC<TUIOptionsProps> = React.forwardRef<HTMLUListElement, TUIOptionsProps>(function UIOptions(
    {
        open,
        options,
        filter,
        filtering,
        filteringInclude,
        applyFilterFn,
        selectOnNavigation,

        onSelect,
        onClose,
        onEnter,

        fluid,
        current,
    },
    ref,
) {
    const inputRef = useRef(null);

    const [ selected, setSelected ] = useState(-1);
    const [ filteredOptions, setFilteredOptions ] = useState<TUIOptionsOption[]>([]);

    useEffect(() => {
        if (filter && filtering) {
            setFilteredOptions(
                options.filter((i) =>
                    applyFilterFn ? applyFilterFn(filter, i) :
                        filteringInclude
                            ? (i.text as string).toLowerCase().includes(String(filter).toLowerCase())
                            : (i.text as string).toLowerCase().startsWith(String(filter).toLowerCase()),
                ),
            );
        } else {
            setFilteredOptions(options);
        }
    }, [ options, filter, filtering, filteringInclude, applyFilterFn ]);

    const returnSelected = useCallback((index: number) => {
        const item = filteredOptions[index];
        if (item) {
            onSelect(item);
        }
    }, [ filteredOptions, onSelect ]);

    const handlerArrows = useCallback((e: KeyboardEvent) => {
        if (!open) {
            return;
        }
        switch (e.key) {
            case 'ArrowDown': {
                e.preventDefault();
                if (!filteredOptions.length) {
                    return;
                }
                const index = (selected + 1 >= filteredOptions.length) ? 0 : selected + 1;
                setSelected(index);
                if (selectOnNavigation) {
                    returnSelected(index);
                }
                scrollToSelectedItem(getRefTarget<HTMLUListElement>(inputRef, ref));
            }
                break;
            case 'ArrowUp': {
                e.preventDefault();
                if (!filteredOptions.length) {
                    return;
                }
                const index = (selected - 1 < 0) ? filteredOptions.length - 1 : selected - 1;
                setSelected(index);
                if (selectOnNavigation) {
                    returnSelected(index);
                }
                scrollToSelectedItem(getRefTarget<HTMLUListElement>(inputRef, ref));
            }
                break;
            case 'Enter': {
                e.preventDefault();
                if (!filteredOptions.length || selected === -1) {
                    return;
                }
                returnSelected(selected);

                if (onEnter) {
                    onEnter();
                }
                if (onClose) {
                    onClose();
                }
            }
                break;
            case 'Escape': {
                e.preventDefault();
                if (onClose) {
                    onClose();
                }
            }
                break;
            default:
                return;
        }
    }, [ open, filteredOptions.length, selected, selectOnNavigation, ref, returnSelected, onEnter, onClose ]);

    useEventListener('keydown', handlerArrows);

    useEffect(() => {
        let existIndex = 0;
        if (open) {
            existIndex = options.findIndex((i) => i.text === filter);
        }
        setSelected(existIndex === -1 ? 0 : existIndex);
    }, [ filter, open, options ]);

    const handlerClick = useCallback<TUIOptionsSelectHandler>((item) => {
        const index = filteredOptions.findIndex((i) => i.value === item.value);
        setSelected(index);
        onSelect(item);
    }, [ filteredOptions, onSelect ]);

    const handlerCLickOutside = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [ onClose ]);


    return (
        <OutsideClickHandler
            onOutsideClick={ handlerCLickOutside }
        >
            <ul
                role='listbox'
                ref={ refGetter<HTMLUListElement>(inputRef, ref) }
                className={ cn('FUI-options', {
                    '-opened': open,
                    '-fluid': fluid,
                }) }
            >
                { filteredOptions.length === 0 && (
                    <div className='FUI-options-empty'>Нет подходящих вариантов</div>
                ) }
                { filteredOptions.map((item, idx) => (
                    <UIOption
                        key={ itemKey(item, idx) }
                        item={ item }
                        onClick={ handlerClick }
                        selected={ idx === selected }
                        picked={ item.picked || item.value === current }
                        disabled={ item.disabled }
                    />
                )) }
            </ul>
        </OutsideClickHandler>
    );
});
