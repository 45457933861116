import cn from 'classnames';
import styled, { css } from 'styled-components';
import { em, mixinMediaBreakpoint } from 'finbox-ui-kit/utils/styles';
import { TUIGridColumn, TUIGridColumnProps, TUIGridComponent, TUIGridProps, TUIGridRow, TUIGridRowProps } from './grid';

const UIGridColumnStyled = styled.div<TUIGridColumnProps>`

  ${ (props) => !props.fluid && css`
    grid-column: span ${ props.colspan || 1 };
  ` }
  ${ (props) => !props.fluid && props.rowspan && css`
    grid-row: span ${ props.rowspan };
  ` }
  ${ (props) => props.fluid && css`
    grid-column: 1 / span var(--row-columns);
  ` }
  ${ (props) => props.verticalAlign === 'center' && css`
    align-items: center;
  ` }
`;

const UIGridRowStyled = styled.div<TUIGridRowProps>`
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-row-gap: ${ (props) => props.gap }em;
  grid-column-gap: ${ (props) => props.gap }em;
  --row-columns: ${ (props) => props.columns };

  ${ (props) => css`
    grid-template-columns: repeat(${ props.columns }, 1fr);
  ` }

  ${ (props) => props.stretched && css`
    align-items: stretch;
    
    & > ${ UIGridColumnStyled } {
      display: inline-flex !important;
      align-self: stretch;
      flex-direction: column;
    }
  ` }
`;

const UIGridStyled = styled.div<TUIGridProps>`
  display: grid;
  gap: ${ (props) => em(props.rowGap) };
  
  ${ (props) => props.noRowGap && css`
    & > ${ UIGridRowStyled } {
      grid-row-gap: 0;
    }
  ` }
  ${ (props) => props.bordered && css`
    & > ${ UIGridRowStyled } > ${ UIGridColumnStyled } {
      border: 1px solid red;
    }
  ` }
  ${ (props) => props.stackable && mixinMediaBreakpoint.down('md', css`
    & > ${ UIGridRowStyled } {
      grid-template-columns: repeat(1, minmax(284px, 1fr));

      & > ${ UIGridColumnStyled } {
        grid-column: 1 !important;
      }
    }
  `) }
`;

export const UIGrid: TUIGridComponent = ({
    children,
    className,
    style,
    stackable,
    noRowGap,
    bordered,
    rowGap = 16,
}) => (
    <UIGridStyled
        className={ className }
        style={ style }
        noRowGap={ noRowGap }
        stackable={ stackable }
        bordered={ bordered }
        rowGap={ rowGap }
    >
        { children }
    </UIGridStyled>
);

export const UIGridRow: TUIGridRow = ({
    children,
    className,
    style,
    noRowGap,
    stretched,
    columnAlign,
    columns = 16,
    gap = 1,
}) => (
    <UIGridRowStyled
        className={ cn(className, {
            '-no-row-gap': noRowGap,
        }) }
        style={ {
            ...columnAlign && { alignItems: columnAlign },
            ...style && style,
        } }
        columns={ columns }
        gap={gap}
        stretched={stretched}
    >
        { children }
    </UIGridRowStyled>
);

export const UIGridColumn: TUIGridColumn = ({
    children,
    className,
    style = {},
    fluid,
    colspan,
    rowspan,
    onClick,
    verticalAlign,
}) => (
    <UIGridColumnStyled
        className={ className }
        style={ style }
        colspan={ colspan }
        rowspan={ rowspan }
        fluid={ fluid }
        onClick={ onClick }
        verticalAlign={ verticalAlign }
    >
        { children }
    </UIGridColumnStyled>
);

UIGrid.Row = UIGridRow;
UIGrid.Col = UIGridColumn;