import { useCallback, useEffect } from 'react';

export function useLockScrolling() {
    useEffect(() => () => {
        document.body.style.overflow = 'auto';
    }, []);
    const lockScrolling = useCallback(() => {
        document.body.style.overflow = 'hidden';
    }, []);
    const unlockScrolling = useCallback(() => {
        document.body.style.overflow = 'auto';
    }, []);
    return {
        lockScrolling,
        unlockScrolling,
    };
}