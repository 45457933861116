import { useEffect } from 'react';
import scrollToElement from 'scroll-to-element';
import cn from 'classnames';
import { useDebounce, useToggle } from 'finbox-ui-kit/utils/hooks';
import { Button } from '@/common/ui';
import styles from './top-button.module.scss';

const onClick = (e) => {
    e.preventDefault();
    scrollToElement('body', {
        offset: 0,
        duration: 300,
    });
};

export function TopButton() {
    const { on, toggle } = useToggle();
    const { debounce } = useDebounce({ timeout: 100 });

    const handlerScroll = () => {
        debounce(() => {
            if (window.scrollY > 50) {
                toggle(true);
            } else {
                toggle(false);
            }
        });
    }

    useEffect(() => {
        window.addEventListener('scroll', handlerScroll);
        return () => {
            window.removeEventListener('scroll', handlerScroll);
        }
    }, [ handlerScroll ]);

    return (
        <Button
            className={ cn(styles.topButton, {
                [styles.topButtonVisible]: on,
            }) }
            icon='up-from-line'
            color='grey'
            onClick={ onClick }
            iconType='solid'
        />
    );
}