import { Button } from '@/common/ui';
import { TPageButton } from '@/common/ui/page/page';
import styles from './page.module.scss';

type TPageButtonsProps = {
    buttons: TPageButton[];
}
export const PageButtons = ({ buttons }: TPageButtonsProps) => (
    <div className={ styles.pageButtons }>
        { buttons.map((button, index) => {
            if (typeof button === 'object' && 'text' in button) {
                return (
                    <Button
                        key={ index }
                        content={ button.text }
                        onClick={ button.onClick }
                        color={ button.color }
                        icon={ button.icon }
                        disabled={ Boolean(button.disabled) }
                    />
                );
            }
            return button;
        }) }
    </div>
);