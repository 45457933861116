import { Modal, useModal } from '@/common/ui';

export const PreviewImage = ({ children, src }) => {
    const { show, props } = useModal();

    return (
        <>
            <div onClick={ () => show() }>
                { children }
            </div>
            <Modal
                { ...props }
                size='large'
                showCloseButton
            >
                <img src={ src } alt=''/>
            </Modal>
        </>
    );
};
