import { Controller, FieldValues } from 'react-hook-form'
import { ReactElement } from 'react';
import { Checkbox } from './checkbox';
import { CheckboxControlledProps } from './checkbox.types';


export function CheckboxControlled<TFieldValues extends FieldValues = FieldValues>({
    control,
    ...props
}: CheckboxControlledProps<TFieldValues>): ReactElement {
    return (
        <Controller
            control={ control }
            name={ props.name }
            render={ ({ field }) => (
                <Checkbox
                    { ...field }
                    { ...props }
                    checked={ field.value }
                    onChange={ (e, { checked }) => {
                        field.onChange(checked);
                    } }
                />
            ) }
        />
    )
}