import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { UICardsList } from 'finbox-ui-kit';
import { formatInitials } from 'finbox-ui-kit/utils';
import { EBorrowerType, MBorrowerType } from '@/consts';
import { TCoBorrowersForm, TStepProps } from '@/components/leads/components/client-edit-forms/types';
import { BorrowerForm } from '@/components/leads/components/client-edit-forms/components/borrower-form';
import { AddBorrowerButton } from '@/components/leads/components/client-edit-forms/components/add-borrower-button';
import { thirdPartyPersonsStepScheme } from '@/components/leads/components/client-edit-forms/schemas/third-party-persons-step.scheme';
import { useCoBorrowers } from '@/components/leads/components/client-edit-forms/use-co-borrowers';
import { Block, Button, Divider, Grid } from '@/common/ui';

export const ThirdPartyPersonsStep = ({
    fields,
    nextStep,
    prevStep,
}: TStepProps) => {
    const { updateCoBorrowers, loading } = useCoBorrowers({ clientId: fields.id });

    const [ currentItemIndex, setCurrentItemIndex ] = useState(null);

    const form = useForm<TCoBorrowersForm>({
        resolver: thirdPartyPersonsStepScheme,
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = form;

    useEffect(() => {
        reset({
            borrowers: fields.borrowers,
        });
    }, [ fields.borrowers, reset ]);

    const {
        fields: items,
        append,
        remove,
    } = useFieldArray<TCoBorrowersForm, 'borrowers'>({
        control,
        name: 'borrowers',
    });

    const handlerSubmit = useCallback((next = false) => async (fields: TCoBorrowersForm) => {
        await updateCoBorrowers(fields.borrowers);
        nextStep(next, false);
    }, [ nextStep, updateCoBorrowers ]);

    const handlerClickAddBorrower = React.useCallback((type: EBorrowerType) => {
        append({
            type: type,
            initials: {
                name: '',
                surname: '',
                patronymic: '',
            },
            phone: '',
            workAndEducation: {
                education: null,
                employmentType: null,
                company: {} as any,
                employee: {} as any,
            },
        });
        setCurrentItemIndex(items.length);
    }, [ append, items.length ]);

    const handlerRemoveBorrower = React.useCallback((index) => () => {
        remove(index);
        setCurrentItemIndex(null);
    }, [ remove ]);

    const handlerClickCard = useCallback((index: number) => () => {
        setCurrentItemIndex(index);
    }, []);

    return (
        <>
            <Block.Group>
                <UICardsList>
                    { items.map((borrower, index) => (
                        <UICardsList.Item
                            key={ index }
                            upperTitle={ MBorrowerType.get(borrower.type) }
                            title={ formatInitials(borrower.initials) }
                            onRemove={ handlerRemoveBorrower(index) }
                            onClick={ handlerClickCard(index) }
                            color={ Object.keys(errors).some((i) => i.startsWith(`borrowers.${ index }`)) ? 'red' : null }
                        />
                    )) }
                    <div>
                        <AddBorrowerButton onPick={ handlerClickAddBorrower }/>
                    </div>
                </UICardsList>
                { currentItemIndex !== null && (
                    <form onSubmit={ handleSubmit(handlerSubmit(false)) }>
                        <Divider/>
                        <FormProvider { ...form }>
                            <BorrowerForm key={ items[currentItemIndex]?.id } index={ currentItemIndex }/>
                        </FormProvider>
                    </form>
                ) }

            </Block.Group>
            <div className='mt1 mb2'>
            </div>
            <Grid stackable>
                <Grid.Row
                    columns={ 1 }
                    style={ {
                        position: 'sticky',
                        bottom: 0,
                        zIndex: 2,
                        background: ' #fff',
                    } }
                >
                    <Grid.Col>
                        <Button
                            className='mr-5'
                            content='Сохранить и продолжить'
                            onClick={ handleSubmit(handlerSubmit(true)) }
                            loading={ loading }
                            primary
                        />
                        <Button
                            className='mr-5'
                            content='Сохранить'
                            onClick={ handleSubmit(handlerSubmit(false)) }
                            loading={ loading }
                            primary
                        />
                        <Button
                            content='Отмена'
                            onClick={ prevStep }
                        />
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        </>
    );
};
