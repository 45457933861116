import React from 'react';
import { UILabel, UITooltip } from 'finbox-ui-kit';
import { TCounters } from 'finbox-ui-kit/components/top-menu/desktop/top-menu';

type MenuItemCountersProps = {
    className: string;
    itemCounters: string[];
    counters: TCounters;
};

export function MenuItemCounters({ className, itemCounters, counters }: MenuItemCountersProps): React.ReactElement {
    return (
        <UILabel.Group className={ className }>
            { itemCounters
                .filter((counter) => counters[counter])
                .map((counter, index) => (
                    <UITooltip
                        key={ index }
                        trigger={ (ref) => (
                            <UILabel ref={ ref } color={ counters[counter].color }>{ counters[counter].count }</UILabel>
                        ) }
                        content={ counters[counter].tooltip }
                    />
                )) }
        </UILabel.Group>
    );
}