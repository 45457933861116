import { Controller, FieldValues } from 'react-hook-form'
import { ReactElement } from 'react';
import { InputControlledProps } from './input.types';
import { Input } from './input';


export function InputControlled<TFieldValues extends FieldValues = FieldValues>({
    control,
    ...props
}: InputControlledProps<TFieldValues>): ReactElement {
    return (
        <Controller
            control={ control }
            name={ props.name }
            render={ ({ field }) => (
                <Input
                    { ...field }
                    { ...props }
                    ref={ (current) => {
                        field.ref(current);
                        if (typeof props.ref === 'function') {
                            props.ref(current)
                        } else if (props.ref) {
                            props.ref.current = current;
                        }
                    } }
                />
            ) }
        />
    )
}