import { useMemo } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Login from '@/components/login';
import { NewLeadNotification } from "@/components/notifications/new-lead";
import { AuthState, AuthUser } from '@/state/auth.state';
import { ChangePasswordRequired } from '@/components/settings/users/components/change-password-required';
import IncomingCall from '@/components/calls/components/incoming-call';
import { useAuthContext } from '@/context/auth.context';
import { ClientTasksContextProvider } from '@/context/client-tasks.context';
import { ClientContextProvider } from '@/context/client.context';
import { AppContextProvider } from '@/context/app.context';
import { TopButton } from '@/common/top-button';
import { AppRoutes } from '@/app-routes';
import { NotificationsProvider } from '@/context/notifications.context';
import { AppLoadingOverlay } from '@/common/app-loading-overlay';

const RenderToast = () =>
    ReactDOM.createPortal(
        <ToastContainer
            autoClose={ 4000 }
            style={ { top: 59, zIndex: 999999, filter: 'none !important' } }
            theme='colored'
            closeOnClick
            draggable={false}
        />,
        document.getElementById('portal'),
    );

const queryClient = new QueryClient()

const AppRoot = () => {
    const { isLoadingCheckAuth } = useAuthContext();
    const isAuth = useAtomValue(AuthState);
    const user = useAtomValue(AuthUser);

    const isNeedPasswordChange = useMemo(() => {
        if (user && !user.admin) {
            return DateTime.fromISO(user.last_change_password) < DateTime.now().minus({ month: 1 });
        }
        return false;
    }, [ user ]);

    return (
        <QueryClientProvider client={queryClient}>
            <AppLoadingOverlay visible={isLoadingCheckAuth}/>

            { !isAuth && (
                <Login/>
            ) }

            { isAuth && !isLoadingCheckAuth && (
                <AppContextProvider>
                    <NotificationsProvider>
                        <NewLeadNotification/>
                        <AppRoutes/>
                        <ClientContextProvider>
                            <ClientTasksContextProvider>
                                <IncomingCall/>
                            </ClientTasksContextProvider>
                        </ClientContextProvider>
                        { isNeedPasswordChange && (
                            <ChangePasswordRequired/>
                        ) }
                        <TopButton/>
                    </NotificationsProvider>
                </AppContextProvider>
            ) }
            <ScrollRestoration/>
            <RenderToast key='com.toast'/>
        </QueryClientProvider>
    );
};


export default AppRoot;
