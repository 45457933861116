import { ReactElement } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { InputInitials } from '@/common/ui';
import { InputInitialsControlledProps } from '@/common/ui/input-initials/types';

export function InputInitialsControlled<TFieldValues extends FieldValues = FieldValues>({
    control,
    ...props
}: InputInitialsControlledProps<TFieldValues>): ReactElement {
    return (
        <Controller
            control={ control }
            name={ props.name }
            render={ ({ field }) => (
                <InputInitials
                    { ...field }
                    { ...props }
                />
            ) }
        />
    )
}