// import * as Sentry from "@sentry/react";
import { createRoot } from 'react-dom/client';
import { PRODUCTION } from '@/consts';
import App from './app';
import './utils/tab-unique-identificator';

if (PRODUCTION) {
    // Sentry.init({
    //     dsn: "https://174bd9610af960f2af475fa754beca43@sentry.fbox.ru/6",
    //     environment: "production",
    //     integrations: [
    //         Sentry.browserTracingIntegration(),
    //         Sentry.replayIntegration(),
    //     ],
    //     // Tracing
    //     tracesSampleRate: 1.0, //  Capture 100% of the transactions
    //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //     tracePropagationTargets: [ "localhost", /^https:\/\/ffo24\.ru\/api/ ],
    //     // Session Replay
    //     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // });
}


const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App/>);
