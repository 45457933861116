import { atom, PrimitiveAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { TUser } from '@/types/manager';


export const AuthState = atom<boolean>(false);


export const AuthUser = atom<TUser | null>(null) as PrimitiveAtom<TUser | null>;
export const LeftMenuState = atomWithStorage<boolean>('leftMenu', false);

