import { useEffect, useRef } from 'react';
import { useSocketsContext } from '@/libs/sockets/sockets.context';
import { SOCKET_EVENT } from '@/consts';

const NewLeadNotification = () => {
    const audioRef = useRef<HTMLAudioElement>(null);
    const { subscribe, unsubscribe } = useSocketsContext();

    useEffect(() => {
        const newLeadHandler = async (data: any) => {
            if (!data?.isManual) {
                await audioRef.current.play();
            }
        };
        subscribe(SOCKET_EVENT.NEW_LEAD, newLeadHandler);
        return () => {
            unsubscribe(SOCKET_EVENT.NEW_LEAD, newLeadHandler);
        };
    }, [ subscribe, unsubscribe ]);

    return (
        <audio ref={audioRef} className='my_audio' controls preload='true' style={{
            position: 'absolute',
            top: 0,
            left: -9999,
        }}>
            <source src='/assets/sounds/new_lead_notify_3.mp3' type='audio/mpeg' />
        </audio>
    );
};

export {
    NewLeadNotification as default,
    NewLeadNotification,
};
