import { DateTime } from 'luxon';
import cn from 'classnames';
import { createArray } from 'finbox-ui-kit/utils';
import { DatePickerCalendarDay, TCalendarDayRenderProps } from '@/common/ui/date-picker-calendar/date-picker-calendar-day';
import styles from './date-picker-calendar.module.scss';

type DatePickerCalendarProps = {
    className?: string
    onClickDay: (date: DateTime) => void;
    onRenderDay: (day: DateTime) => TCalendarDayRenderProps
    currentMonth?: DateTime;
};

export function DatePickerCalendar({ className, onClickDay, onRenderDay, currentMonth }: DatePickerCalendarProps) {
    const currentDate = currentMonth || DateTime.now();
    const firstDayOfMonthIdx = currentDate.startOf('month').weekday;

    return (
        <div className={ cn(styles.dayPickerCalendar, className) }>
            <div className={ styles.dayPickerCalendarWeekDays }>
                <span>Пн</span>
                <span>Вт</span>
                <span>Ср</span>
                <span>Чт</span>
                <span>Пт</span>
                <span>Сб</span>
                <span>Вс</span>
            </div>
            <div className={ styles.dayPickerCalendarDays }>
                { ...createArray(firstDayOfMonthIdx - 1).map((i) => (
                    <div key={ i } className={ styles.dayPickerCalendarDaysDay }></div>
                )) }
                { ...createArray(currentDate.daysInMonth || 0).map((i) => (
                    <DatePickerCalendarDay
                        key={ i + firstDayOfMonthIdx }
                        day={ currentDate.set({ day: i + 1 }) }
                        onClick={ onClickDay }
                        renderProps={ onRenderDay(currentDate.set({ day: i + 1 })) }
                    />
                )) }
            </div>
        </div>
    );
}