import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { omit } from 'lodash';


import { isDefined, refGetter , MakeUISyntheticChangeEvent } from 'finbox-ui-kit/utils';
import { TUIDropdownMultipleProps } from './dropdown-multiple';
import { UIOptions } from '../options';
import { TUIOptionsOption, TUIOptionsSelectHandler } from '../options/options';

import { UIInput } from '../input';
import { OutsideClickHandler } from '../../utils/hooks';

import './dropdown-multiple.style.scss';


export const UIDropdownMultiple: React.FC<TUIDropdownMultipleProps> = React.forwardRef<HTMLInputElement, TUIDropdownMultipleProps>(function UIDropdownMultiple(
    {
        options,
        ...props
    },
    ref) {
    const localRef = useRef(null);
    const [ opened, setOpened ] = useState(false);
    const [ selected, setSelected ] = useState<TUIOptionsOption[]>([]);
    const [ filteredOptions, setFilteredOptions ] = useState<TUIOptionsOption[]>([]);

    const handlerArrows = useCallback((e: any) => {
        if (e.key === 'Tab' && opened) {
            e.preventDefault();
            setOpened(false);
        }
    }, [ opened ]);

    useEffect(() => {
        window.addEventListener('keydown', handlerArrows);
        return () => {
            window.removeEventListener('keydown', handlerArrows);
        };
    });

    useEffect(() => {
        setSelected(options.filter((item) => (props.value || []).map((i) => JSON.stringify(i)).includes(JSON.stringify(item.value))));
        setFilteredOptions(options.map((item) => ({
            ...item,
            picked: (props.value || []).map((i) => JSON.stringify(i)).includes(JSON.stringify(item.value)),
        })));
    }, [ options, props.value ]);


    const handlerSelect = useCallback<TUIOptionsSelectHandler>((item) => {
        const exists = selected.some((i) => i.value === item.value);
        let _selected;

        if (exists) {
            _selected = selected.filter((i) => i.value !== item.value);
        } else {
            _selected = [
                ...selected,
                item,
            ];
        }
        setSelected(_selected);

        props.onChange(
            MakeUISyntheticChangeEvent((ref as any)?.current || localRef.current, _selected.map((i) => i.value)),
            {
                data: undefined,
                name: props.name,
                oldValue: props.value,
                value: _selected.map((i) => i.value),
            },
        );
    }, [ props, ref, selected ]);

    const handlerClick = useCallback((e: any) => {
        if (!props.readOnly) {
            setOpened((c) => !c);
        }
        if (props.onClick) {
            props.onClick(e);
        }
    }, [ props ]);

    const handlerFocus = useCallback((e: any, data: any) => {
        if (!props.readOnly) {
            setOpened(true);
        }
        if (props.onFocus) {
            props.onFocus(e, data);
        }
    }, [ props ]);

    const handlerBlur = useCallback((e: any, data: any) => {
        if (props.onBlur) {
            props.onBlur(e, data);
        }
    }, [ props ]);

    const handlerChangeInput = useCallback((e: any, { value }: any) => {
        if (!value) {
            setSelected([]);
            setOpened(false);
            setFilteredOptions(options.map((item) => ({
                ...item,
                picked: false,
            })));
            props.onChange(
                MakeUISyntheticChangeEvent((ref as any)?.current || localRef.current, null),
                {
                    data: undefined,
                    name: props.name,
                    oldValue: props.value,
                    value: null,
                },
            );
        }
    }, [ options, props, ref ]);

    const handlerClickOutside = useCallback(() => {
        if (opened) {
            setOpened(false);
        }
    }, [ opened ]);

    return (
        <OutsideClickHandler onOutsideClick={ handlerClickOutside }>
            <UIInput
                { ...omit(props, 'onChange') }
                type='text'
                ref={ refGetter(localRef, ref) }
                className={ cn('FUI-dropdown FUI-dropdown-multiple', props.className, {
                    '-opened': opened,
                }) }
                onFocus={ handlerFocus }
                onBlur={ handlerBlur }
                onMouseDown={ handlerClick }
                onChange={ handlerChangeInput }
                suggestionsAddon={ (
                    <>
                        <UIOptions
                            open={ opened }
                            options={ filteredOptions }
                            onSelect={ handlerSelect }
                            fluid
                        />
                        <div className='FUI-dropdown-multiple-value'>
                            <span>
                                { selected.length > 0 && selected.map((item) => item.text).join(', ') }
                            </span>
                        </div>
                    </>
                ) }
                autoComplete='off'
                value={ selected.length ? 'not_empty' : '' }
                rightIcon={ (!isDefined(props.value) || selected.length === 0 ) ? 'chevron-down' : 'xmark' }
                clearable={ selected.length > 0 }
                readOnly
            />
        </OutsideClickHandler>
    );
});
